// 
// Header
// 

(function($) {

	// 
	// Header menu
	// 
	$('#js-header-mobile-menu-toggle').on('click', function() {
		$('.header-mobile-menu').toggleClass('is-active');
	});
	$('.header-mobile-menu a').on('click', function() {
		$('.header-mobile-menu').removeClass('is-active');
		$('.header-nav-toggle').removeClass('is-active');
	});


	// 
	// jQuery.smoothScroll
	// 
	$('.tabs-title > [href^="#"]').addClass('noscroll');

	$('[href^="#"]:not( [href^="#about"], [href^="#news"] )').SmoothScroll({
		duration: 700,
		easing: 'swing',
		hash: false
	});

	$('[href^="#about"]').SmoothScroll({
		duration: 700,
		easing: 'swing',
		offset: 40,
		hash: false
	});
	$('[href^="#news"]').SmoothScroll({
		duration: 700,
		easing: 'swing',
		offset: 80,
		hash: false
	});

})(jQuery);